<template>
  <task-list :is-personal="isPersonal" :is-dashboard="isDashboard" :show-card-toggle="!isDashboard && !isPersonal" v-if="isVisible"></task-list>
</template>
<script>
import  { mapGetters } from 'vuex'
import TaskList from '@/components/Tasks/TaskList'


export default {
  components: {
    TaskList
  },
  props: {
    isPersonal: {
      type: Boolean,
      default: true
    },
    isDashboard: {
      type: Boolean,
      default: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
