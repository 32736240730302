<template>
  <pie-chart-widget  id="montageliste" :data="chartData" :options="extraOptions" :title="$t('message.assembly')" :title-link="`/project/${$route.params.id}/assembly-planning`" :is-visible="isVisible"></pie-chart-widget>
</template>
<script>
  import { mapGetters } from 'vuex'
  import colors from '@/assets/sass/custom/_color-variables.scss'
  import PieChartWidget from '../PieChartWidget'

  export default {
    name: 'MontageChartWidget',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    components: {
      PieChartWidget
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'montageStates',
        'montageStateValues'
      ]),
      extraOptions () {
        const self = this
        return {
          responsive: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = Object.values(self.montageStates)
                var total = Object.values(self.montageStates).reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue
                });
                var currentValue = dataset[tooltipItem.index];
                return `${currentValue}/${total}`
              }
            }
          },
          onClick (e, a) {
            if (a.length) {
              self.jumpToFilteredResults(a[0]._index)
            }
          }
        }
      },
      chartData () {
        const labels = this.montageStateValues
          .map(v => this.$t('message.mountedStates.' + v))
        return {
            labels,
            datasets: [{
              data: Object.values(this.montageStates),
              backgroundColor: [
                colors.apaStateRed,
                colors.apaStateGreen

              ]
          }],

        }
      }
    },
    methods: {
        jumpToFilteredResults (index) {
          this.$store.commit('bhbFilterType', 'mounted');
          // Index + 1; 0 = Alle
          this.$store.commit('bhbFilterValue', index.toString());
          this.$router.push({ path: `/project/${this.$route.params.id}/bhb` })
        }
      }
  };
</script>
