<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center">
        <div class="col-md-6 pt-3 pb-2">
          <nav aria-label="breadcrumb" class="d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-md-6 pt-3 pb-2 text-right">
          <base-button size="sm" type="primary" v-if="isPositioningMode" @click="onSavePositioningHandler()">
            {{ $t('message.save') }}
          </base-button>
          
          <base-button size="sm" type="primary" @click="updatePics" :loading="loading"
            v-if="currentUserRights.includes('project.dashboard.canexport') && project.pt_sync_enabled == 1 && project.pt_number">
            {{ $t('message.pt_update') }}
          </base-button>

          <base-button size="sm" type="primary" @click="report"
            v-if="currentUserRights.includes('project.dashboard.canexport')">
            {{ $t('message.export') }}
          </base-button>
          <el-tooltip :content="$t('message.settings')" class="positioning_button" placement="left"
            v-if="currentUserRights.includes('Einstellungen_Anordnung und Größe der Widgets')">
            <base-dropdown menu-on-right>
              <base-button slot="title-container" icon size="sm" type="primary" class="dropdown-toggle">
                <span class="btn-inner--icon"><i class="fas fa-cogs"></i></span>
              </base-button>
              <a class="dropdown-item" @click="onPositioningHandler()">{{ $t('message.changePosition') }}</a>
              <a class="dropdown-item" @click="onResetLayout()">{{ $t('message.reset-layout') }}</a>

            </base-dropdown>
          </el-tooltip>
        </div>
      </div>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--6">
      <grid-layout ref="grid" v-if="testLayout.length > 0" :layout="testLayout" :col-num="3" :row-height="30"
        :is-draggable="isPositioningMode" :is-resizable="isPositioningMode" :is-mirrored="false" :responsive="true"
        :breakpoints="{ lg: 1200, md: 1024, sm: 768, xs: 480, xxs: 0 }" :cols="{ lg: 3, md: 2, sm: 2, xs: 1, xxs: 1 }"
        :vertical-compact="true" :margin="[10, 10]" :use-css-transforms="false">
        <grid-item v-for="layout in testLayout" :key="layout.i" :x="layout.x" :y="layout.y" :w="layout.w" :h="layout.h"
          :i="layout.i">
          <component :is="layout.component" :is-personal="false" :is-dashboard="true" :is-visible="layout.isVisible" />
        </grid-item>
      </grid-layout>
    </div>
    <insurance-frontpage-modal :show.sync="showInsuranceFrontpageModal"
      :project="projects[0]"></insurance-frontpage-modal>
  </div>
</template>
<script>
import ProjectTable from '@/components/ProjectTable'
import FavProjectTable from '@/components/FavProjectTable'

import projects from '@/data/projects'
import TaskWidget from './Widgets/Tasks/Tasks';
import MilestoneList from './Widgets/Milestones/MilestoneList';
import Calendar from './Widgets/CalendarWidget';
import Timeline from './Widgets/TimelineWidget';
import BhbChartWidget from './Widgets/Charts/BHB/Index.vue'
import KommissionierungChartWidget from './Widgets/Charts/Kommissionierung/Index.vue'
import LadelisteChartWidget from './Widgets/Charts/Ladeliste/Index.vue'
import MontagelisteChartWidget from './Widgets/Charts/Montageliste/Index.vue'
import RuecklagerungslisteChartWidget from './Widgets/Charts/Ruecklagerungsliste/Index.vue'
import PtstatusChartWidget from './Widgets/Charts/PTStatus/Index.vue'
import CommentsCard from "./Project/BHB/DetailView/CommentsCard";

import PieChartWidget from './Widgets/Charts/PieChartWidget';
import InsuranceFrontpageModal from '@/components/TransportInsurance'

import ProjectNavBar from './Project/ProjectNavBar';

import { Charts } from "@/components/Charts/config";
import { mapGetters, mapActions } from 'vuex'

import Vue from 'vue'
import { b64toBlob } from '@/util/data'


function randomScalingFactor() {
  return Math.round(Math.random() * 100);
}
import colors from '@/assets/sass/custom/_color-variables.scss'

const defaultLayout = [
  { "x": 2, "y": 0, "w": 1, "h": 13, "i": "0", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "task-widget" },
  { "x": 0, "y": 0, "w": 1, "h": 13, "i": "1", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "timeline" },
  { "x": 0, "y": 13, "w": 1, "h": 13, "i": "2", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "bhb-chart-widget" },
  { "x": 1, "y": 13, "w": 1, "h": 13, "i": "3", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "kommissionierung-chart-widget" },
  { "x": 2, "y": 13, "w": 1, "h": 13, "i": "4", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "ladeliste-chart-widget" },
  { "x": 1, "y": 0, "w": 1, "h": 13, "i": "5", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "milestone-list" },
  { "x": 0, "y": 26, "w": 1, "h": 13, "i": "6", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "montageliste-chart-widget" },
  { "x": 1, "y": 26, "w": 1, "h": 13, "i": "7", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "ruecklagerungsliste-chart-widget" },
  { "x": 0, "y": 39, "w": 1, "h": 13, "i": "8", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "ptstatus-chart-widget" },
  /*{ "x": 2, "y": 26, "w": 1, "h": 13, "i": "8", "resizable": true, "draggable": true, "static": false, "moved": false, "component": "konfektion-chart-widget" },*/
]

export default {
  components: {
    ProjectTable,
    FavProjectTable,
    TaskWidget,
    ProjectNavBar,
    Calendar,
    PieChartWidget,
    Timeline,
    MilestoneList,
    InsuranceFrontpageModal,
    BhbChartWidget,
    KommissionierungChartWidget,
    LadelisteChartWidget,
    MontagelisteChartWidget,
    RuecklagerungslisteChartWidget,
    PtstatusChartWidget,
    CommentsCard
  },
  computed: {
    favoriteProjects() {
      return this.data.projects.filter(item => item.isFavorite === true)
    },
    ...mapGetters([
      'projects',
      'project',
      'bhbStates',
      'currentUser',
      'currentUserRights'
    ]),
    rawLayout() {
      if (this.currentUser.layout_project && this.resetLayout === null) {
        this.tempLayout = JSON.parse(this.currentUser.layout_project)
      } else if (this.resetLayout !== null) {
        this.tempLayout = this.resetLayout
        this.resetLayout = null
      } else {
        this.tempLayout = JSON.parse(JSON.stringify(defaultLayout))
      }

      const tempLayout = this.tempLayout.map(l => {
        l.isVisible = false
        return l
      })

      const setVisibleById = id => {
        const layoutItem = tempLayout.find(l => l.i === id.toString())
        if (layoutItem) {
          layoutItem.isVisible = true
        }
      }

      if (this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen) ') && this.hasFunction('T')) {
        setVisibleById(0)
      }

      if (this.currentUserRights.includes('Dashboard Projekt A_Benachrichtigungsfeld')) {
        setVisibleById(1)
      }

      if (this.currentUserRights.includes('project.bhb.isvisible') && this.hasFunction('B')) {
        setVisibleById(2)
      }

      if (this.currentUserRights.includes('project.kommissionierungsliste.isvisible') && this.hasFunction('K')) {
        setVisibleById(3)
      }

      if (this.currentUserRights.includes('project.ladeliste.isvisible') && this.hasFunction('L')) {
        setVisibleById(4)
      }

      if (this.currentUserRights.includes('pdashboard.widget.milestones')) {
        setVisibleById(5)
      }

      if (this.currentUserRights.includes('Montageplanung_Ansicht') && this.hasFunction('M')) {
        setVisibleById(6)
      }

      if (this.currentUserRights.includes('project.rueckeinlagerungsliste.isvisible') && this.hasFunction('U')) {
        setVisibleById(7)
      }

      if (this.currentUserRights.includes('project.bhb.isvisible') && this.project.pt_number && this.currentUser.role_id !== 13 && this.currentUser.role_id !== 14) {
        setVisibleById(8)
      }

      return tempLayout

    },
    testLayout: {
      get() {
        return this.rawLayout
          .filter(li => li.isVisible)
      },
      set(value) {
        this.tempLayout = value
      }
    }
  },
  data() {
    return {
      showInsuranceFrontpageModal: false,
      isPositioningMode: false,
      data: {
        projects
      },
      tempLayout: [],
      resetLayout: null,
      loading: false
    };
  },
  methods: {
    ...mapActions([
      'refreshCurrentUser',
      'loadPositionsForPorject'
    ]),
    hasFunction(func) {
      return this.project ? this.project.functions.includes(func) : false;
    },
    onPositioningHandler() {
      this.isPositioningMode = !this.isPositioningMode
    },
    async onSavePositioningHandler() {
      const sanitizedLayout = this.rawLayout.map(l => {
        delete l.isVisible
        return l
      })
      await this.$store.dispatch('updateUser', { id: this.currentUser.id, data: { layout_project: JSON.stringify(sanitizedLayout) } });
      this.$notify({ verticalAlign: 'top', horizontalAlign: 'right', message: 'Einstellung erfolgreich gespeichert.', type: 'success' });
      this.isPositioningMode = false;
      await this.refreshCurrentUser()
      // this.testLayout = JSON.parse(this.currentUser.layout_project)
    },
    async report() {
      const data = {}
      const ids = ['bhb', 'kommissionierung', 'ladeliste', 'montageliste', 'rueckladeliste']
      ids.forEach(name => {
        const canvas = document.querySelector('#pie-chart-' + name + ' canvas')
        if (canvas) {
          data['chart-' + name] = canvas.toDataURL()
        }
      })

      const url = 'pdf/project/' + this.project.id + '/dashboard'
      const response = await Vue.http['post'](url, data);

      const blob = b64toBlob(response.body.content);

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.project.name + "-Dashboard.pdf";
      link.click();
    },
    onResetLayout() {
      const self = this
      this.resetLayout = JSON.parse(JSON.stringify(defaultLayout))
      this.$nextTick(() => {
        self.onSavePositioningHandler()
      })
    },
    async updatePics() {
      try {
        this.loading = true;
        await Vue.http['get']('pt/picsupdate/' + this.project.pt_number, {});
        this.loading = false;
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Updates wurden eingelesen.',
          type: 'success'
        })
      } catch (response) {
        this.$notify({
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Fehler beim Update aus PT: <br>' + response.body.message,
          type: 'error'
        });
        this.loading = false;
      }
    }
  },
  mounted() {
  }
}
</script>
<style>
@media (max-width: 1291px) {
  .vue-grid-layout {
    height: auto !important;
  }

  .vue-grid-layout .vue-grid-item {
    position: static !important;
    padding: 6px;
    float: left;
    width: 50% !important;
  }

  .positioning_button {
    display: none;
  }
}

@media (max-width: 859px) {
  .vue-grid-layout .vue-grid-item {
    margin: 6px 0;
    float: none;
    width: 100% !important;
  }
}
</style>
