<template>
  <pie-chart-widget id="bhb"
    :data="chartData"
    :options="extraOptions"
    :title="titleText"
    :title-link="`/project/${$route.params.id}/bhb`"
    :is-visible="isVisible">
  </pie-chart-widget>
</template>
<script>
  import { mapGetters } from 'vuex'
  import colors from '@/assets/sass/custom/_color-variables.scss'
  import PieChartWidget from '../PieChartWidget'
  export default {
    name: 'BHBHhartWidget',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    components: {
      PieChartWidget
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'bhbStates',
        'bhbStateValues',
        'authorisedStateValues',
        'projectAddressPositions',
        'currentUserRights',
        'IMPositions'
      ]),
      extraOptions () {
        const self = this;
        return {
          responsive: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          showAllTooltips: true,
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = Object.values(self.bhbStates)
                var total = Object.values(self.bhbStates).reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue
                });
                var currentValue = dataset[tooltipItem.index];
                return `${currentValue}/${total}`
              }
            }
          },
          onClick (e, a) {
            if (a.length) {
              self.jumpToFilteredResults(a[0]._index)
            }
          }
        }
      },
      chartData () {
        const labels = this.authorisedStateValues
          .map(v => this.$t('message.bhbState.' + v))
          // .map(v => v.toUpperCase())
        return {
            labels,
            datasets: [{
              data: Object.values(this.bhbStates),
              backgroundColor: this.buildColors(this.authorisedStateValues),
              datalabels: {
                anchor: 'center'
              }
          }]
        }
      },
      titleText () {
        let text = `${this.$t('message.bhb_short')} (${this.IMPositions.length}) `
        if (this.currentUserRights.includes('bhb.status.can-modify-handlung-pl')) {
          text = text + `(${this.$t('message.actionPL')}: ${this.IMPositions.filter(i => i.handlung_pl == 1).length})`
        }

        if (this.currentUserRights.includes('bhb.status.can-modify-wirtschaftliche-freigabe')) {
          text = text + ` (${this.$t('message.rel_business')}: ${this.IMPositions.filter(i => i.rel_business == 1).length})`
        }
        return text
      }
    },
    methods: {
      buildColors (states) {
        const result = []
        if (states.includes('LEER')) {
          result.push(colors.apaStateBlue)
        }
        if (states.includes('OFFEN')) {
          result.push(colors.apaStateRed)
        }
        if (states.includes('KORREKTUR')) {
          result.push(colors.apaStateYellow)
        }
        if (states.includes('FREIGEGEBEN')) {
          result.push(colors.apaStateGreen)
        }
        if (states.includes('IN_PRODUKTION')) {
          result.push(colors.apaStateDarkGreen)
        }
        return result
      },
      jumpToFilteredResults (index) {
        this.$store.commit('bhbFilterType', 'state');
        // Index + 1; 0 = Alle
        this.$store.commit('bhbFilterValue', index + 1);

        this.$router.push({ path: `/project/${this.$route.params.id}/bhb` })
      }
    }
  };
</script>
