<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">{{ $t('news.title') }}</h6>

    <base-input type="text" label="Titel" v-if="item" v-model="item.subject"></base-input>
    <base-input label="Text">
      <el-input type="textarea" autosize v-if="item" v-model="item.comment"></el-input>

    </base-input>
    <el-upload ref="upload" action="#" multiple :before-remove="onFilesBeforeRemove" :http-request="setDocument"
      :on-change="onFileChange" :limit="5">
      <base-button type="default">
        <i class="fas fa-paperclip"></i>
      </base-button>
    </el-upload>
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onShowChange(false)">Abbrechen</base-button>
      <base-button type="primary" @click="onSave">Speichern</base-button>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength, between } from "vuelidate/lib/validators";
import api from "@/util/api.js";
import omit from 'lodash/omit'
import moment from "moment";
import CommentForm from "@/components/Feed/CommentForm";


export default {
  name: "news-form",
  components: {
    CommentForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      fileList: []
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'externalPersonTopics'
    ])
  },
  methods: {
    ...mapActions([
    ]),
    onFilesBeforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    setDocument(data) {
      this.fileList.push(data.file);
      return true
    },
    uploadFile(id) {
      if (this.fileList) {
        const files = {
          'comment_attachment[]': this.fileList
        };
        return this.$store.dispatch('uploadDocument', { type: 'comment_attachment', id, files })
      }
    },
    onShowChange(state) {
      if (!state) this.$emit("cancel");
    },
    async onSave() {
      if (this.item.id) {
        await this.$store.dispatch('updateComment', { id: this.item.id, data: this.item })
        await this.uploadFile(this.item.id);
      } else {
        delete this.item.id
        this.item.type = "NEWSFEED"
        this.item.user_id = this.currentUser.id
        this.item.created = moment().format("YYYY-MM-DD HH:mm:ss");
        const { id } = await this.$store.dispatch('createComment', { data: this.item })
        await this.uploadFile(id);
      }
      this.fileList = []
      this.$refs.upload.clearFiles()
      this.$emit("change");
    },
  },
  mounted() {
    ;
  }
};
</script>
