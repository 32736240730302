<template>
  <card>
    <template slot="header">
      <!-- Subtitle -->
      <h6 class="surtitle" v-if="surTitle">{{surTitle}}</h6>
      <!-- Title -->
      <router-link v-if="titleLink" :to="titleLink">
        <h5 class="h3 mb-0">{{title}}</h5>
      </router-link>
      <h5 class="h3 mb-0" v-else>{{title}}</h5>
    </template>
    <div class="chart" :id="'pie-chart-'+id" v-if="isVisible">
      <pie-chart
        :height="350"
        :chart-data="data"
        :extra-options="realOptions"
      >
      </pie-chart>
    </div>
    <slot />
  </card>
</template>
<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import format from 'date-fns/format'
  import { Charts } from "@/components/Charts/config"
  import PieChart from '@/components/Charts/PieChart'

  const today = new Date();
  export default {
    name: 'PieChartWidget',
    components: {
      FullCalendar,
      PieChart
    },
    props: {
      id: {
        type: String,

      },
      title: {
        type: String,

      },
      titleLink: {
        type: String,
        default: ''
      },
      surTitle: {
        type: String,

      },
      data: {
        type: Object
      },
      options: {
        type: Object
      },
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {

      };
    },
    computed: {
      realOptions () {
        return Object.assign({
          legend: {
            position: 'bottom'
          },
          hover: {
            onHover: function(e, a) {
              e.target.style.cursor = a[0] ? 'pointer' : 'default'
            }
          }
        }, this.options)
      }
    }
  };
</script>

