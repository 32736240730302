<template>
  <div>
    <project-nav-bar ></project-nav-bar>
    <fade-transition :duration="200" origin="center top" mode="out-in">
      <router-view name="project"></router-view>
    </fade-transition>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import ProjectNavBar from './Project/ProjectNavBar';
  import { FadeTransition } from 'vue2-transitions';
  import store from '@/store'

  export default {
    components: {
      ProjectNavBar,
      FadeTransition
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'project',
        'currentUserRights'
      ])
    },
    methods: {
      ...mapActions([
        'getFullProject',
        'loadPositionsForPorject',
        'getMilestonesForProject',
        'loadPersonsForProject',
        'loadPersonAddresses',
        'loadRidesForProject',
        'getAddressesByProjectId',
        'loadProjectOrders',
        'loadProjectOptions',
        'loadGlobalOptions',
        'loadProjectPeople',
        'loadProjectDocs',
        'getNotifications',
        'clearProjectFilter'
      ]),
    },
    beforeRouteLeave (to, from , next) {
      store.commit('project', null);
      next()
    },
    mounted() {
      const self = this
      const checkProjectInfos = Promise.all([
        this.getFullProject(this.$route.params.id),
        this.loadProjectPeople(this.$route.params.id)
      ])
      this.getAddressesByProjectId(parseInt(this.$route.params.id));
      // this.getMilestonesForProject(this.$route.params.id);
      this.loadPersonsForProject(this.$route.params.id);
      this.loadPersonAddresses(this.$route.params.id);
      this.loadPositionsForPorject(this.$route.params.id);
      this.loadRidesForProject(this.$route.params.id);
      this.loadProjectOrders(this.$route.params.id);
      this.loadGlobalOptions();
      this.loadProjectOptions(this.$route.params.id);
      this.loadProjectPeople(this.$route.params.id);
      this.loadProjectDocs(this.$route.params.id)

      checkProjectInfos.then(() => {
        const currentUserFound = self.project.projectPersons.some(pp => pp.user.id === self.currentUser.id)
        if (!currentUserFound && !self.currentUserRights.includes('Alle Projekte_')) {
          self.$router.push('/my-dashboard')
        }
      })
    },
    destroyed () {
      this.$store.commit("SET_CURRENT_PROJECT_ADDRESS", 0)
    }
  };
</script>
<style></style>
