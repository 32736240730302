
<template>
   <modal @update:show="onClose" :show="show" >
    <h6 slot="header" class="modal-title">Transportversicherung</h6>
    <div class="row" v-if="project">
      <div class="col-md-12">
        <base-input label="Auftragsnummer" v-model="project.name" disabled>
        </base-input>
        <base-input label="Einsatzort" v-model="project.location" disabled>
        </base-input>
        <base-input label="Beschreibung"
            <textarea v-model="project.description"
              height="300"
              class="form-control form-control-alternative edit-event--description textarea-autosize"
              placeholder="Beschreibung"></textarea>
        </base-input>
        <base-input label="Ladetermin" v-model="project.eventDate" disabled>
        </base-input>
        <base-input label="Aufladen vor Ort" v-model="project.eventDate" disabled>
        </base-input>
        <div class="row">
          <div class="col-md-6">
            <base-input label="Zu versichern von APA ">
              <base-checkbox>Transport</base-checkbox>
              <base-checkbox>Aufenthalt vor Ort</base-checkbox>
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input label="Zu versichern von Veranstalter/Kunde">
              <base-checkbox>Transport</base-checkbox>
              <base-checkbox>Aufenthalt vor Ort</base-checkbox>
            </base-input>
          </div>
        </div>
        <base-input label="Warenwert laut Auftrag" v-model="project.sumPrice" disabled>
        </base-input>
       
      </div>
    </div>
    <template slot="footer">
        <base-button type="primary" @click="show = false">Erstellen</base-button>
        <base-button type="neutral" @click="show = false">Abbrechen</base-button>
    </template>
  </modal>
</template>
<script>
  // Charts
  import { mapGetters } from 'vuex'   

  export default {
    name: 'TransportInsurance',
    components: {

    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      project: {
        type: Object
      }
    },
    computed: {
      ...mapGetters([
        'projects'
      ]),
      colClass () {
        const width = this.items.length < this.columns ? Math.floor(12/this.items.length) : 12 / this.columns
        return `col-md-${width}`
      }
    },
    data() {
      return {
        projectName: 'Pokalfinale 2019',
        content: '',
        fileList: []
      }
    },
    methods: {
      onClose (state) {
        this.$emit('update:show', state)
      },
      copySelection () {

      },
      deleteSelection () {

      },
      handleExceed(files, fileList) {
        this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
      }
    },
    mounted() {
 
    }
  };
</script>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #505050;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar-preview {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>