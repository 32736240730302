<template>
  <base-dashboard>
    <template slot="menu">
      <base-button size="sm" type="primary" v-if="isPositioningMode" @click="onSavePositioningHandler()">
        {{ $t('message.save') }}
      </base-button>
      <el-tooltip :content="$t('message.settings')" placement="left" v-if="currentUserRights.includes('Einstellungen_Anordnung und Größe der Widgets')">
        <base-dropdown menu-on-right>
          <base-button slot="title-container" icon size="sm" type="primary" class="dropdown-toggle">
            <span class="btn-inner--icon"><i class="fas fa-cogs"></i></span>
          </base-button>
            <a class="dropdown-item" @click="onPositioningHandler()">{{$t('message.changePosition')}}</a>
            <a class="dropdown-item" @click="onResetLayout()">{{$t('message.reset-layout')}}</a>
        </base-dropdown>
      </el-tooltip>
    </template>
      <div class="container-fluid mt--6">
        <grid-layout
				:layout="testLayout"
				:col-num="3"
				:row-height="30"
				:is-draggable="isPositioningMode"
				:is-resizable="isPositioningMode"
				:is-mirrored="false"
				:responsive="true"
        :breakpoints="{ lg: 1200, md: 1024, sm: 768, xs: 480, xxs: 0 }"
        :cols="{ lg: 3, md: 2, sm: 2, xs: 1, xxs: 1 }"
				:vertical-compact="true"
				:margin="[10, 10]"
				:use-css-transforms="false">
            <grid-item
              v-for="layout in testLayout"
              :key="layout.i"
              :x="layout.x"
              :y="layout.y"
              :w="layout.w"
              :h="layout.h"
              :i="layout.i">
              <component :is="layout.component" :is-personal="true"  :is-visible="layout.isVisible" />
					</grid-item>
       </grid-layout>
      </div>
  </base-dashboard>
</template>
<script>
  import  FavProjectTable from '@/components/FavProjectTable'
  import  ProjectTable from '@/components/ProjectTable'

  import TaskWidget from './Widgets/Tasks/Tasks'
  import NewsFeed from './Widgets/NewsFeed/NewsFeed'
  import Calendar from './Widgets/CalendarWidget'
  import Timeline from './Widgets/TimelineWidget'

  // import projects from '@/data/projects'
  import { mapGetters, mapActions } from 'vuex'

  const defaultLayout = [{"x":0,"y":0,"w":3,"h":13,"i":"0","resizable":true,"draggable":true,"static":false,"moved":false,"component":"fav-project-table"},
                         {"x":2,"y":13,"w":1,"h":14,"i":"1","resizable":true,"draggable":true,"static":false,"moved":false,"component":"task-widget"},
                         {"x":0,"y":27,"w":3,"h":16,"i":"2","resizable":true,"draggable":true,"static":false,"moved":false,"component":"news-feed"},
                         {"x":0,"y":13,"w":2,"h":14,"i":"3","resizable":true,"draggable":true,"static":false,"moved":false,"component":"timeline"}]

  export default {
    components: {
      ProjectTable,
      FavProjectTable,
      TaskWidget,
      NewsFeed,
      Calendar,
      Timeline
    },
    computed: {
      ...mapGetters([
        'projects',
        'currentUser',
        'currentUserRights'
      ]),
      rawLayout () {
        if (this.currentUser.layout_home && this.resetLayout === null) {
          this.tempLayout = JSON.parse(this.currentUser.layout_home)
        } else if (this.resetLayout !== null) {
          this.tempLayout = this.resetLayout
          this.resetLayout = null
        } else {
          this.tempLayout = JSON.parse(JSON.stringify(defaultLayout))
        }

        const tempLayout = this.tempLayout.map(l => {
          l.isVisible = false
          return l
        })

        const setVisibleById = id => {
          const layoutItem = tempLayout.find(l => l.i === id.toString())
          if (layoutItem) {
            layoutItem.isVisible = true
          }
        }

        // Fav table
        setVisibleById(0)

        // Tasks
        if (this.currentUserRights.includes('ToDo´s Mein Dashboard_')) {
          setVisibleById(1)
        }

        // Newsfeed
        if (this.currentUserRights.includes('Newsfeed_Ansicht/ Übersicht')) {
          setVisibleById(2)
        }

        // Notifications
        if (this.currentUserRights.includes('Dashboard Projekt A_Benachrichtigungsfeld')) {
          setVisibleById(3)
        }

        return tempLayout
        
      },
      testLayout: {
        get () {
          return this.rawLayout
            .filter(li => li.isVisible)
        },
        set (value) {
          this.tempLayout = value
        }
      }
    },
    data() {
      return {
        isPositioningMode: false,
        tempLayout: [],
        resetLayout: null
      };
    },
    methods: {
      ...mapActions([
        'refreshCurrentUser'
      ]),
      onPositioningHandler () {
        this.isPositioningMode = true
      },
      async onSavePositioningHandler () {
        const sanitizedLayout = this.rawLayout.map(l => {
          delete l.isVisible
          return l
        })
        await this.$store.dispatch('updateUser', { id: this.currentUser.id, data: {layout_home: JSON.stringify(sanitizedLayout)}});
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', message: 'Einstellung erfolgreich gespeichert.', type: 'success'});
        this.isPositioningMode = false;
        await this.refreshCurrentUser()
        this.testLayout = JSON.parse(this.currentUser.layout_home)
      },
      onResetLayout () {
        const self = this
        this.resetLayout = JSON.parse(JSON.stringify(defaultLayout))
        this.$nextTick(() => {
          self.onSavePositioningHandler()
        })
      }
    },
    mounted() {
      if (this.currentUser.layout_home) {
        this.testLayout = JSON.parse(this.currentUser.layout_home)
      }
    }
  };
</script>
<style>
.vue-resizable-handle{
  z-index: 99 !important;
}
</style>
