<template>
  <card>
    <template slot="header">
      <h3>{{ title }}</h3>
    </template>

    <apa-comments 
      @stateChanged="stateChanged" 
      @createdDebriefing="setHasDebriefing" 
      :reloadComments="{triggerReload: true}"
      :fixtures="{
        project_id: project ? project.id : null,
        position_id: position ? position.id : null,
        type: { 'in': ['COMMENT', 'KORR_COMMENT'] },
      }" />
  </card>
</template>

<script>
import ApaComments from "@/components/ApaComments/ApaComments";
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "comments-card",
  components: {
    ApaComments,
  },
  props: {
    position: Object,
    title: String
  },
  computed: {
    ...mapGetters([
      'project',
    ]),
  },
  data() {
    return {
      alreadyScrolled: false,
      alreadyBlinked: false,
      commentId: -1
    };
  },
  methods: {
    ...mapActions([
      'setBhbHasDebriefing'
    ]),
    stateChanged(newState) {
      this.$emit('stateChanged', newState);
    },
    async setHasDebriefing(){
      await this.setBhbHasDebriefing({ posId: this.position.id })
    },
    blink: function (el) {
      let count = 0;
      el.style.background = '#de979c'
      let blinker = setInterval(() => {
        if (count === 5) {
          el.style.background = 'white'
          clearInterval(blinker)
        }

        if (count % 2) {
          el.style.background = 'white'
        }
        else {
          el.style.background = '#de979c'
        }
        count = count + 1;
      }, 200);

    }
  },
  mounted() {
    setTimeout(() => {
      let comment = document.getElementById('comment-' + this.$route.query.comment_id)
      this.commentId = comment;
      if (comment && !this.alreadyScrolled) {
        this.alreadyScrolled = true
        comment.scrollIntoView({ behavior: "smooth" });
        setTimeout(this.blink(comment), 3000);
      }
    }, 1000)

  }
}
</script>

