<template>
 <widget-card v-loading="isLoading">
   <template slot="header">
    <h5 class="h3 mb-0">{{$t('message.milestones')}}</h5>
   </template>
    <ul class="list-group list-group-flush" data-toggle="checklist" v-if="widgetMilestones.length > 0">
      <li class="list-group-item px-0"
          v-for="item in widgetMilestones"
          :key="item.title">
        <div class="milestone-item">
          <div class="milestone-info">
            <h5 class="milestone-title mb-0">{{item.name}} {{taskState(item) }}</h5>
            <small v-if="item.projectAddress">{{item.projectAddress.address.name}}</small>
          </div>
          <div>
            <small class="d-block text-right">{{item.due | date}}</small>
            <small v-if="daysDiff(item) > 0">{{$t('message.daysRemaining', { days: item.dueDays })}}</small>
            <small v-if="daysDiff(item) <= 0 && !allTasksDone(item)" class="text-red">{{$t('message.due', { diff: daysDiff(item, true) * -1 } )}}</small>
            <small v-if="allTasksDone(item)" class="text-green">{{$t('message.done')}}</small>
          </div>
        </div>
      </li>
    </ul>
    <div v-else>{{$t('message.noData')}}</div>

 </widget-card>
</template>
<script>
import  { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { TASK_CLOSED, TASK_NEW } from "@/store/modules/tasks/constants";

export default {
  computed: {
    ...mapGetters([
      'widgetMilestones'
    ])
  },
  data() {
    return {
      items: [],
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'getMilestonesForProject'
    ]),
    load: function() {
      this.isLoading = true
      this.getMilestonesForProject(this.$route.params.id)
        .then(items => {
          this.isLoading = false;
        })
    },
    daysDiff(milestone, round = false) {
      const now = moment();
      const then = moment(milestone.due);
      const diff = then.diff(now, 'days', true)
      if (round) {
        return Math.round(diff)
      }
      return diff
    },
    taskState (item) {
      if (!item.tasks) {
        return ''
      }
      const closedTasks = item.tasks.filter(t => t.state === TASK_CLOSED).length
      return `(${closedTasks} von ${item.tasks.length} Aufgaben)`
    },
    allTasksDone (item) {
      if (!item.tasks) {
        return false
      }
      const closedTasks = item.tasks.filter(t => t.state === TASK_CLOSED).length
      return closedTasks === item.tasks.length
    }
  },
  mounted() {
    this.load();
  }
};
</script>
