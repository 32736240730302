<template>
  <pie-chart-widget  id="kommissionierung" :data="chartData" :options="extraOptions" :title="$t('message.pickingList')" :title-link="`/project/${$route.params.id}/picking-list`" :is-visible="isVisible"></pie-chart-widget>
</template>
<script>
  import { mapGetters } from 'vuex'
  import colors from '@/assets/sass/custom/_color-variables.scss'
  import PieChartWidget from '../PieChartWidget'

  export default {
    name: 'KommissionierungChartWidget',
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    components: {
      PieChartWidget
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'project'
      ]),
      extraOptions () {
        const self = this
        return {
          responsive: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var dataset = Object.values(self.project.progress.chart.komm)
                var total = Object.values(self.project.progress.chart.komm).reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue
                });
                var currentValue = dataset[tooltipItem.index];
                return `${currentValue}/${total}`
              }
            }
          },
          onClick (e, a) {
            if (a.length) {
              self.jumpToFilteredResults(a[0]._index)
            }
          }
        }
      },
      chartData () {
        const labels = Object.keys(this.project.progress.chart.komm)
          .map(v => this.$t('message.commStates.' + v))
          // .map(v => v.toUpperCase())
        return {
            labels,
            datasets: [{
              data: Object.values(this.project.progress.chart.komm),
              backgroundColor: [
                colors.apaStateRed,
                colors.apaStateYellow,
                colors.apaStateGreen
              ]
          }],

        }
      }
    },
    methods: {
      jumpToFilteredResults (index) {
        this.$store.commit('kommFilterType', 'state')
        // Index + 1; 0 = Alle
        this.$store.commit('kommFilterValue', index + 1)
        this.$router.push({ path: `/project/${this.$route.params.id}/picking-list` })
      }
    },
    mounted () {
    }
  };
</script>
