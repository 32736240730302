var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('div',{staticClass:"project-nav-bar"},[_c('div',{staticClass:"pos-f-t"},[_c('div',{staticClass:"collapse",attrs:{"id":"navbarToggleExternalContent"}},[_c('div',{staticClass:"bg-white p-4"},[_c('ul',{staticClass:"navbar-nav"},[_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id)),"exact":"","tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.dashboard")))])]),(
            _vm.currentUserRights.includes(
              'Dashboard Projekt A_Bearbeitung der Projektdaten'
            )
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/edit"),"exact":"","tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.projectFacts")))])]):_vm._e(),(
            _vm.currentUserRights.includes('Dateneingabe_Ansicht') &&
            _vm.hasFunction('D')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/data"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.dataInput")))])]):_vm._e(),(
            _vm.hasFunction('B') &&
            _vm.currentUserRights.includes('project.bhb.isvisible')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/bhb"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.bhb")))])]):_vm._e(),(
            _vm.currentUserRights.includes('project.chat.cansee') &&
            _vm.hasFunction('C')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/chat"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.chat")))])]):_vm._e(),(_vm.additionlLinks.length)?_c('apa-dropdown',{staticClass:"nav-item",attrs:{"tag":"li"},scopedSlots:_vm._u([{key:"dropdown",fn:function(){return _vm._l((_vm.additionlLinks),function(link){return _c('router-link',{key:link.id,staticClass:"dropdown-item",attrs:{"exact":"","to":link.url}},[_vm._v(_vm._s(link.label))])})},proxy:true}],null,false,1371026177)},[_c('div',{staticClass:"nav-link dropdown-toggle"},[_vm._v(_vm._s(_vm.$t("message.more")))])]):_vm._e()],1)])])]),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('div',{staticClass:"navbar-brand",staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(_vm.project.key)+" - "+_vm._s(_vm.project.name)+" ")]),_c('button',{staticClass:"navbar-toggler btn btn-primary",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarTogglerDemo03","aria-controls":"navbarTogglerDemo03","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.toggleNav}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('div',{staticClass:"inline-navbar navbar-collapse justify-content-end"},[_c('ul',{staticClass:"navbar-nav"},[_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id)),"exact":"","tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.dashboard")))])]),(
            _vm.currentUserRights.includes(
              'Dashboard Projekt A_Bearbeitung der Projektdaten'
            )
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/edit"),"exact":"","tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.projectFacts")))])]):_vm._e(),(
            _vm.currentUserRights.includes('Dateneingabe_Ansicht') &&
            _vm.hasFunction('D')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/data"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.dataInput")))])]):_vm._e(),(
            _vm.hasFunction('B') &&
            _vm.currentUserRights.includes('project.bhb.isvisible')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/bhb"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.bhb")))])]):_vm._e(),(
            _vm.currentUserRights.includes('project.chat.cansee') &&
            _vm.hasFunction('C')
          )?_c('router-link',{staticClass:"nav-item",attrs:{"to":("/project/" + (_vm.$route.params.id) + "/chat"),"tag":"li"}},[_c('a',{staticClass:"nav-link"},[_vm._v(_vm._s(_vm.$t("message.chat")))])]):_vm._e(),(_vm.additionlLinks.length)?_c('apa-dropdown',{staticClass:"nav-item",attrs:{"tag":"li"},scopedSlots:_vm._u([{key:"dropdown",fn:function(){return _vm._l((_vm.additionlLinks),function(link){return _c('router-link',{key:link.id,staticClass:"dropdown-item",attrs:{"exact":"","to":link.url}},[_vm._v(_vm._s(link.label))])})},proxy:true}],null,false,1371026177)},[_c('div',{staticClass:"nav-link dropdown-toggle"},[_vm._v(_vm._s(_vm.$t("message.more")))])]):_vm._e()],1)])]),_c('div',{staticClass:"project_subnav tablet-hide"},[_c('div',{staticClass:"project_infos"},[_c('router-link',{attrs:{"to":("/project/" + (_vm.$route.params.id))}},[(
            _vm.unreadProjectNotifications(_vm.$route.params.id).length > 0 &&
            _vm.currentUserRights.includes(
              'Dashboard Projekt A_Benachrichtigungsfeld'
            )
          )?_c('el-badge',{staticClass:"project-notification-item",attrs:{"value":_vm.unreadProjectNotifications(_vm.$route.params.id).length}},[_c('i',{staticClass:"fas fa-bell faa-shake animated"})]):_vm._e()],1),(_vm.project.state == 'ARCHIVED')?_c('i',{staticClass:"fas fa-archive ml-1"}):_vm._e(),_c('project-header')],1),(!_vm.$route.meta.hideAddressSelector)?_c('div',{staticClass:"project_addresses_selection"},[_c('el-select',{staticClass:"select-primary",attrs:{"filterable":""},model:{value:(_vm.projectAddress),callback:function ($$v) {_vm.projectAddress=$$v},expression:"projectAddress"}},[_c('el-option',{attrs:{"label":_vm.$t('message.allProjectAddresses'),"value":0}}),_vm._l((_vm.projectAddresses),function(option){return _c('el-option',{key:option.address.id,staticClass:"select-primary",attrs:{"value":option.address.id,"label":option.address.name}})})],2)],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }