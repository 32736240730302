<template>
  <component
    class="apaDropdown"
    ref="element"
    tabindex="0"
    :class="isTopAligned ? 'dropup' : 'dropdown'"
    :is="tag"
    @focusin="onFocusIn"
    @focusout="onFocusOut"
  >
    <slot />
    <div
      class="dropdown-menu"
      :class="{ 'dropdown-menu-right': isRightAligned, 'show': isExpanded }"
      @click="onMenuClick"
    >
      <slot name="dropdown" />
    </div>
  </component>
</template>

<script>
export default {
  name: "apa-dropdown",
  props: {
    tag: {
      type: String,
      default: "div"
    }
  },
  data() {
    return {
      frameId: null,
      isExpanded: false,
      isRightAligned: false,
      isTopAligned: false
    };
  },
  unmount() {
    this.cancelFrame();
  },
  watch: {
    isExpanded(value) {
      this.cancelFrame();
      this.requestFrame();
    }
  },
  methods: {
    cancelFrame() {
      const { frameId } = this;
      this.frameId = null;

      if (frameId) {
        window.cancelAnimationFrame(frameId);
      }
    },
    isChildElement(child) {
      const { element } = this.$refs;
      while (child) {
        if (child === element) return true;
        child = child.parentElement;
      }

      return false;
    },
    onFocusIn(event) {
      this.isExpanded = true;
    },
    onFocusOut(event) {
      this.isExpanded = this.isChildElement(event.relatedTarget);
    },
    onFrame() {
      const { element } = this.$refs;
      if (!element) {
        return;
      }

      const rect = element.firstElementChild.getBoundingClientRect();
      const x = rect.left + rect.width * 0.5;
      const y = rect.top + rect.height * 0.5;

      this.isRightAligned = x > window.innerWidth * 0.5;
      this.isTopAligned = y > window.innerHeight * 0.5;

      this.requestFrame();
    },
    onMenuClick() {
      this.isExpanded = false;
    },
    requestFrame() {
      this.frameId = this.isExpanded
        ? window.requestAnimationFrame(this.onFrame)
        : null;
    }
  }
};
</script>

<style>
.apaDropdown {
  outline: none;
  user-select: none;
}
</style>
