<template>


  <!-- Messages -->
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <!-- Title -->
      <div class="row">
        <div class="col-6">
          <h5 class="h3 mb-0">{{ $t('message.newsfeed') }}</h5>
        </div>
        <div class="col-6 text-right">
          <base-checkbox v-model="showMoreNews" class="checkbox mr-6 d-inline">{{
            $t('message.show_more')
          }}</base-checkbox>

          <el-tooltip :content="$t('message.new')" placement="top">
            <base-button type="primary" icon size="sm" @click="onItemClicked(null)">
              <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            </base-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- Card body -->
    <div class="card-body p-0">
      <!-- List group -->
      <div class="list-group list-group-flush">
        <news-item class="clickable" v-for="item in news" :item="item" :key="item.id"
          v-on:item-clicked="onItemClicked(item)"></news-item>
        <div class="d-flex w-100 justify-content-between" v-if="news.length === 0">
          {{ $t('message.noEntries') }}
        </div>
      </div>
    </div>
    <news-form :show.sync="showForm" :item="item" @change="onChanged" @cancel="showForm = false"></news-form>

    <modal v-if="lastNews" :show="showLastNews" @update:show="dismissNewsModal()">
      <h6 slot="header" class="modal-title"> Letzte {{$t('news.title') }}</h6>
      <div class="d-flex w-100 justify-content-between">
        <div>
          <div class="d-flex w-100 align-items-center">
            <img v-if="lastNews.image" :src="lastNews.image" alt="Image placeholder"
              class="avatar avatar-xs mr-2">
            <h5 class="mb-1">{{ lastNews.user.name }}</h5>
          </div>
        </div>
        <small>{{ lastNews.created | datetime }}</small>
      </div>
      <h4 class="mt-3 mb-1">{{ lastNews.subject }}</h4>
      <p class="text-xs mb-0" v-html="lastNews.comment"></p>
    </modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NewsItem from './NewsItem'
import NewsForm from './NewsForm'
export default {
  components: {
    NewsItem, NewsForm
  },
  props: {
    showProjectId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDetail: false,
      showForm: false,
      showMoreNews: false,
      currentItem: null,
      news: [],
      item: null,
      oldParentStyle: '',
      parentStyle: null,
      showLastNews: true,
      lastNews: null
    };
  },
  watch: {
    showForm(val) {
      if (val == false) {
        this.parentStyle.cssText = this.oldParentStyle
      }
    },
    showMoreNews() {
      var count = this.showMoreNews ? 100 : 3;
      this.load(count);
    }
  },
  methods: {
    onItemClicked(item) {

      //this.modifyParentGridElementStyle()
      this.item = {
        id: item ? item.id : null,
        subject: item ? item.subject : null,
        comment: item ? item.comment : null,
      }
      this.showForm = true
    },
    onChanged() {
      this.load();
      this.showForm = false
    },
    async load(show = 3) {
      this.news = await this.$store.dispatch('loadComments', {
        filter: { type: 'NEWSFEED', pred_id: null },
        order: { created: 'DESC' },
        page: { take: show }
      });
      const lastSeenNews = localStorage.getItem("APA_LAST_NEWS")
      if (new Date(this.news[0].created).getTime() > new Date(lastSeenNews).getTime()){
        this.lastNews = this.news[0];
        this.showLastNews = true
      }
    },
    dismissNewsModal() {
      this.showLastNews = false;
      localStorage.setItem("APA_LAST_NEWS", this.lastNews.created);
    },
    modifyParentGridElementStyle() {

      this.oldParentStyle = JSON.parse(JSON.stringify(this.$parent.$refs.item.style.cssText))
      this.parentStyle = this.$parent.$refs.item.style
      const elements = this.oldParentStyle.split(';')

      const coordinates = elements[0]
        .substr(23)
        .slice(0, -1)
        .split(',')
        .map(i => i.trim())

      // transform entfernen
      elements.splice(0, 1)
      elements.push(`left: ${coordinates[0]}`)
      elements.push(`top: ${coordinates[1]}`)
      this.parentStyle.cssText = elements.join(';')

    },
  },
  async mounted() {
    await this.load()
  }
};
</script>
