<template>
  <div class="card widget-calendar">
    <div class="card-header">
      <h5 class="h3 mb-0">Kalender</h5>
    </div>
    <div class="card-body">
      <!-- <gantt-elastic :tasks="tasks" :options="options" :dynamic-style="dynamicStyle" /> -->
    </div>
  </div>
</template>
<script>
  import GanttElastic from 'gantt-elastic'
  import dayjs from 'dayjs'

  const today = new Date()

  function getDate(hours) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
    return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
  }

  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();

  export default {
    name: 'calendar',
    components: {
      GanttElastic
    },
    methods: {
      // calendarApi () {
      //   const api = this.$refs.fullCalendar.getApi()
      //   return api
      // },
    },
    mounted() {
    },
    data()  {
      let yearAndMonth = `${y}-${m + 1}`
      return {
        dynamicStyle: {
            'task-list-header-label': {
              'font-weight': 'bold'
            }
        },
        tasks: [
          {
            id: 1,
            label: 'DFB-Pokalfinale 2019',
            user: 'Kim Gehrmann',
            start: getDate(-24 * 5),
            duration: 15 * 24 * 60 * 60 * 1000,
            progress: 50,
            type: 'project'
            //collapssed: true,
          },
          {
            id: 2,
            label: 'VB1',
            user: 'Kim Gehrmann',
            parentId: 1,
            start: getDate(-24 * 4),
            duration: 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'milestone',
            collapsed: false,
            style: {
              base: {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              }
              /*'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51'
              }*/
            }
          },
           {
            id: 3,
            label: 'Kick Off Meeting',
            user: 'Kim Gehrmann',
            parentId: 1,
            start: getDate(-24 * 2),
            duration: 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'milestone',
            collapsed: false,
            style: {
              base: {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              }
              /*'tree-row-bar': {
                fill: '#1EBC61',
                stroke: '#0EAC51'
              },
              'tree-row-bar-polygon': {
                stroke: '#0EAC51'
              }*/
            }
          },
          {
            id: 4,
            label: 'Projekt anlegen',
            user: 'Lorena Nickel',
            parentId: 1,
            start: getDate(-24 * 1),
            duration: 2 * 24 * 60 * 60 * 1000,
            progress: 100,
            type: 'task'
          },
          // {
          //   id: 3,
          //   label: 'Courage is being scared to death, but saddling up anyway.',
          //   user:
          //     '<a href="https://www.google.com/search?q=John+Wayne" target="_blank" style="color:#0077c0;">John Wayne</a>',
          //   parentId: 2,
          //   start: getDate(-24 * 3),
          //   duration: 2 * 24 * 60 * 60 * 1000,
          //   progress: 100,
          //   type: 'task'
          // },
          // {
          //   id: 4,
          //   label: 'Put that toy AWAY!',
          //   user:
          //     '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
          //   start: getDate(-24 * 2),
          //   duration: 2 * 24 * 60 * 60 * 1000,
          //   progress: 50,
          //   type: 'task',
          //   dependentOn: [3],
      
          // }
        ],
        options: {
          maxRows: 100,
          maxHeight: 300,
          title: {
            label: 'Your project title as html (link or whatever...)',
            html: false
          },
          row: {
            height: 30
          },
          calendar: {
            hour: {
              display: false
            }
          },
          chart: {
            progress: {
              bar: false
            },
            expander: {
              display: true
            }
          },
          taskList: {
            expander: {
              straight: false
            },
            widthFromPercentage: 0,
            columns: [
              {
                id: 1,
                label: 'ID',
                value: 'id',
                width: 40
              },
              {
                id: 3,
                label: 'Bearbeiter',
                value: 'user',
                width: 130,
                html: true
              },
            ]
          }
          /*locale:{
            name: 'pl', // name String
            weekdays: 'Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota_Niedziela'.split('_'), // weekdays Array
            weekdaysShort: 'Pon_Wto_Śro_Czw_Pią_Sob_Nie'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
            weekdaysMin: 'Pn_Wt_Śr_Cz_Pt_So_Ni'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
            months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split('_'), // months Array
            monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
            ordinal: n => `${n}`, // ordinal Function (number) => return number + output
            relativeTime: { // relative time format strings, keep %s %d as the same
              future: 'za %s', // e.g. in 2 hours, %s been replaced with 2hours
              past: '%s temu',
              s: 'kilka sekund',
              m: 'minutę',
              mm: '%d minut',
              h: 'godzinę',
              hh: '%d godzin', // e.g. 2 hours, %d been replaced with 2
              d: 'dzień',
              dd: '%d dni',
              M: 'miesiąc',
              MM: '%d miesięcy',
              y: 'rok',
              yy: '%d lat'
            }
        }*/
        },
        monthsLocale: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember'
        ],
        eventColors: ['bg-info', 'bg-orange', 'bg-red', 'bg-green', 'bg-default', 'bg-blue', 'bg-purple', 'bg-yellow']
      };
    },
  };
</script>
<style lang="scss">
  @import "~@fullcalendar/core/main.css";
  @import "~@/assets/sass/core/vendors/fullcalendar";
</style>
