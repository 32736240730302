import { Pie, mixins } from 'vue-chartjs';
import globalOptionsMixin from "@/components/Charts/globalOptionsMixin";
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'pie-chart',
  extends: Pie,
  mixins: [mixins.reactiveProp, globalOptionsMixin],
  props: {
    extraOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ctx: null
    };
  },
  mounted() {

    const baseOptions= {
      plugins: {
        datalabels: {
          backgroundColor: function(context) {
            return context.dataset.backgroundColor;
          },
          color: 'white',
          display: function(context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value !== 0;
          },
          font: {
            weight: 'bold'
          },
          formatter: Math.round
        }
      },
    }
    this.$watch(
      'chartData',
      (newVal, oldVal) => {
        if (!oldVal) {
          this.renderChart(this.chartData, Object.assign(this.extraOptions, baseOptions));
        }
      },
      { immediate: true }
    );
    this.addPlugin(ChartDataLabels)
  }
};
