<template>
<div>
  <div class="project-header" v-if="currentAddressId">
    {{ addressName }}
  </div>
  <div class="project-header" v-if="projectPeriod">
    {{projectPeriod}}
  </div>
  <div class="avatar-group">
    <avatar v-for="user in getProjectPersons(project.projectPersons)" :user-id="user.user_id" :key="user.user_id"></avatar>
  </div>
</div>

</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { truncate } from 'lodash'
  import { formatDate } from '@/util/data'

  export default {
    name: 'ProjectNavBar',
    data() {
      return {
        addressName: null
      };
    },
    computed: {
       ...mapGetters([
        'project',
        'projectAddresses',
        'internalPersonTopics'
      ]),
      projectPeriod() {
        const project = {
          projectAddresses: this.projectAddresses
        }
        const fromDates = (project.projectAddresses || [])
            .filter(pa => pa.date_from !== null)
            .map(pa => pa.date_from);
        const toDates = (project.projectAddresses || [])
            .filter(pa => pa.date_to !== null)
            .map(pa => pa.date_to);
        const { length,  0: from, [length - 1]: to } = [...fromDates, ...toDates]
            .sort((a, b) => {
                return new Date(a) - new Date(b);
            })
            .map(d => formatDate(new Date(d)));
        if (!from || !to) {
            return false;
        }
        return `${from} - ${to}`;
      },
      currentAddressId() {
          let response = this.getSingleLocation();
          return response;
      }
    },
    methods: {
      ...mapActions([
        'getBhbPosition'
      ]),
      truncate,
      getProjectPersons (persons) {
        const internalTopics = this.internalPersonTopics.map(it => it.val);
        return persons.filter(p => internalTopics.includes(p.topic));
      },
      async getSingleLocation() {
        if(this.$route.params.posId !== undefined) {
          let currentId = await this.getBhbPosition(this.$route.params.posId).then(r => r.address.id);
          this.addressName = this.projectAddresses.filter(a => a.id == currentId).map(p => p.address.name)[0];
          return currentId;
        } else {
          this.addressName = this.projectAddresses.map(p => p.address.name).join(', ');
          return 0;
        }
      }
    }
  };
</script>
