<template>
  <pie-chart-widget id="ptstatus" :data="chartData" :options="extraOptions" :title="$t('message.pt_state')"
    :is-visible="isVisible"></pie-chart-widget>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import colors from '@/assets/sass/custom/_color-variables.scss'
import PieChartWidget from '../PieChartWidget'

export default {
  name: 'PTStatusChartWidget',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PieChartWidget
  },
  data() {
    return {
      ptStatus: {}
    }
  },
  computed: {
    ...mapGetters([
      'project',
    ]),
    extraOptions() {
      return {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          callbacks: {
            label:  (tooltipItem, data) => {
              var dataset = Object.values(this.ptStatus)
              var total = Object.values(this.ptStatus).reduce(function (previousValue, currentValue, currentIndex, array) {
                return previousValue + currentValue
              });
              var currentValue = dataset[tooltipItem.index];
              return `${currentValue}/${total}`
            }
          }
        }
      }
    },
    chartData() {
      const labels = Object.keys(this.ptStatus)
        .map(v => this.$t('message.ptStatusStates.' + v))
      return {
        labels,
        datasets: [{
          data: Object.values(this.ptStatus),
          backgroundColor: [
            colors.apaStateRed,
            colors.apaStateYellow,
            colors.apaStateGreen
          ]
        }],
      }
    }
  },
  mounted() {
    Vue.http.get('pt/statusupdate/'+this.project.pt_number).then((result,error) => {
      this.ptStatus = result.body;
    })
  }
};
</script>
